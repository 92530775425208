<template>
  <!-- Distribution -->
  <div id="story-mode-distribution">

    <!-- Main Navigation -->
    <main-navigation />
    <!-- /Main Navigation -->

    <!-- Container -->
    <div class="container-1200">

      <!-- Content -->
      <div
        class="content"
        :class="{ 'position-relative': statusOfCompleted }"
      >
        <v-app>
          <!-- Header -->
          <header class="page-header">

            <!-- Dashboard Header -->
            <dashboard-header
              :class="{ 'stamp': statusOfCompleted }"
              :title="$t('pages.businessGuide.distribution.title')"
              :guide-visible="guideVisible"
              :guide-visible-first-time="guideVisibleFirstTime"
              :completed-step-name="'distributionCompleted'"
              hide-quick-tour-button
              @toggle-guide="onGuideExampleVisible"
              @open-comp-dialog="openedDialogProcessCompleted"
              @open-info="openInfo"
              @open-ai-buddy-drawer="aiBuddyDrawerVisible = true"
            />
            <!-- /Dashboard Header -->

            <!-- Divider -->
            <hr>
            <!-- /Divider -->

          </header>
          <!-- /Header -->

          <!-- Info Guide Expand Panel -->
          <info-guide
            v-if="guideVisibleFirstTime"
            class="mb-5"
            type="distributionExamples"
            visible
            @click="guideVisibleFirstTime = !guideVisibleFirstTime"
          />
          <!-- Info Guide Expand Panel -->

          <!-- Ai Buddy Drawer -->
          <ai-buddy-drawer
            :visible="aiBuddyDrawerVisible"
            section-name="Distribution"
            help-me-write
            type="distribution"
            @close="aiBuddyDrawerVisible = false"
          />
          <!-- /Ai Buddy Drawer -->
        </v-app>

        <!-- Business plan additional info -->
        <business-plan-additional-info
          completed-step-name="distributionCompleted"
          section-name="Distribution"
          show-ai-buddy
          @ai-buddy-open="aiBuddyDrawerVisible = true"
        />
        <!-- /Business plan additional info -->

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Portfolio -->
        <div class="portfolio">

          <v-app><!-- Header -->
            <business-guide-header>
              <template #left>
                <h2>{{ $t('pages.businessGuide.distribution.salesAndDistributionMethods') }}</h2>
                <p class="text-lead">
                  {{ $t('pages.businessGuide.distribution.describeAllTheSalesDistribution') }}
                </p>
              </template>
              <template #right>
                <erase-icon-button
                  v-if="removeButtonVisible"
                  @click="onEraseFromTemplate"
                />
                <business-guide-search
                  v-if="!isDistributionsEmpty"
                  v-model="searchValue"
                  class="mx-1"
                />
                <!-- View Switcher -->
                <view-switcher
                  v-if="isDistributionsEmpty === false"
                  :active-view="$store.state.itemsView.distributionsView"
                  @activate-view="onActivateView"
                />
                <!-- /View Switcher -->
              </template>
            </business-guide-header>
            <!-- /Header --></v-app>

          <!-- Grid Wrapper -->
          <div class="grid-wrapper" :class="{ 'active': $store.state.itemsView.distributionsView === 'grid' }">
            <el-row :gutter="20">
              <!-- Completed overlay -->
              <completed-overlay
                v-if="hasNotAccess"
                @click="checkDistributionCard"
              />
              <!-- /Completed overlay -->

              <div
                v-if="isDistributionsEmpty"
                class="d-flex flex-wrap"
              >
                <el-col :md="8" class="d-flex width-lg-20 mb-3">
                  <add-new-card
                    class="width-lg-20"
                    :title="$tc('addSalesChannel')"
                    :disabled="hasNotAccess"
                    @click="dialogAddDistributionVisible = true"
                  />
                </el-col>

                <!-- Placeholder 1 -->
                <el-col v-if="$breakpoint.mdAndUp" :md="8" class="width-lg-20 mb-3">
                  <div class="placeholder-card large">
                    <div class="w-100">
                      <div class="avatar-placeholder blue" />
                      <div class="stripe-medium" />
                      <div class="stripe-small" />
                      <div class="stripe-large" />
                    </div>
                  </div>
                </el-col>
                <!-- /Placeholder 1 -->

                <!-- Placeholder 2 & Tutorial Tooltip -->
                <el-col v-if="$breakpoint.mdAndUp" :md="8" class="width-lg-20 mb-3">
                  <div class="placeholder-card large">

                    <!-- Placeholder 2 -->
                    <div class="w-100">
                      <div class="avatar-placeholder yellow" />
                      <div class="stripe-medium" />
                      <div class="stripe-small" />
                      <div class="stripe-large" />
                    </div>
                    <!-- /Placeholder 2 -->

                    <!-- Tutorial Tooltip TODO - Currently disabled in if directive -->
                    <tutorial-tooltip
                      v-if="$store.state.user.tutorial.distributionCompleted === false && 1 === 2"
                      :title="$t('pages.businessGuide.distribution.addYourSalesChannel')"
                      :text="$t('pages.businessGuide.thisIsStarOfYourShow')"
                      :tutorial-name="'distributionCompleted'"
                    />
                    <!-- /Tutorial Tooltip -->

                  </div>
                </el-col>
                <!-- /Placeholder 2 & Tutorial Tooltip -->
              </div>

              <!-- Grid Items -->
              <div v-if="isDistributionsEmpty === false">
                <ib-draggable
                  class="d-flex flex-wrap"
                  :list="distributionsToShowGrid"
                  :options="{disabled: hasNotAccess}"
                  @change="onChangeDistributionOrder"
                  @start="onStartDistributionOrder"
                >
                  <template #header>
                    <el-col :md="8" class="d-flex width-lg-20 mb-3">
                      <add-new-card
                        class="width-lg-20"
                        :title="$tc('addSalesChannel')"
                        :disabled="hasNotAccess"
                        @click="dialogAddDistributionVisible = true"
                      />
                    </el-col>
                  </template>
                  <el-col
                    v-for="distribution in distributionsToShowGrid"
                    :key="distribution.id"
                    class="d-flex width-lg-20 draggable mb-3"
                    :md="8"
                  >
                    <div
                      class="distribution-card cursor-move w-100"
                      :class="{'completed-card': statusOfCompleted }"
                      @click="checkDistributionCard(distribution)"
                    >
                      <!-- Card Top -->
                      <div
                        class="card-top" :class="{ 'default': !distribution.image }"
                        :style="cardImageBySize(distribution.image, 'medium')"
                      >

                        <!-- Actions Dropdown -->
                        <actions-dropdown
                          v-if="canEdit && !statusOfCompleted"
                          ref="actionsDropdown"
                          :entity="distribution"
                          @edit="onEditDistribution"
                          @copy="onCopyDistribution"
                          @delete="onDeleteDistribution"
                        />
                      <!-- /Actions Dropdown -->

                      </div>
                      <!-- /Card Top -->

                      <!-- Card Bottom -->
                      <div class="card-bottom">

                        <!-- Title -->
                        <h3>{{ distribution.name }}</h3>
                        <!-- /Title -->

                        <!-- Description -->
                        <p class="pre-formatted text-left ml-2 mr-2">
                          {{ distribution.description }}
                        </p>
                      <!-- /Description -->

                      </div>
                    <!-- /Card Bottom -->

                    </div>
                  </el-col>
                </ib-draggable>
              </div>
              <!-- /Grid Items -->
            </el-row>
            <!-- Show All -->
            <el-row v-if="distributions.length > 4">
              <el-col>
                <show-all-toggle
                  :show="showAllDistributionsGrid"
                  @click="showAllDistributionsGrid = !showAllDistributionsGrid"
                />
              </el-col>
            </el-row>
            <!-- /Show All -->
          </div>
          <!-- /Grid Wrapper -->

          <!-- List Wrapper -->
          <div class="list-wrapper" :class="{ 'active': $store.state.itemsView.distributionsView === 'list' }">
            <el-row :gutter="20">
              <!-- Completed overlay -->
              <completed-overlay
                v-if="hasNotAccess"
                @click="checkDistributionCard"
              />
              <!-- /Completed overlay -->

              <ib-draggable
                class="d-flex flex-wrap"
                :list="distributionsToShowList"
                :options="{disabled: hasNotAccess}"
                @change="onChangeDistributionOrder"
                @start="onStartDistributionOrder"
              >
                <template #header>
                  <el-col :md="12" class="d-flex mb-3">
                    <add-new-card
                      class="width-xl-20"
                      :title="$tc('addSalesChannel')"
                      :disabled="hasNotAccess"
                      horizontal
                      @click="dialogAddDistributionVisible = true"
                    />
                  </el-col>
                </template>
                <!-- List Items -->
                <el-col
                  v-for="distribution in distributionsToShowList"
                  :key="distribution.id"
                  :md="12"
                  class="draggable mb-3"
                >
                  <div
                    class="distribution-card cursor-move w-100"
                    :class="{ 'completed-card': statusOfCompleted }"
                    @click="checkDistributionCard(distribution)"
                  >
                    <!-- Card Top -->
                    <div class="card-top">

                      <!-- Card Image & Distribution Name -->
                      <div class="flex-inner">

                        <!-- Card Image -->
                        <div>
                          <div
                            class="card-image-round" :class="{ 'default': !distribution.image }"
                            :style="cardImageBySize(distribution.image, 'small')"
                          />
                        </div>
                        <!-- /Card Image -->

                        <!-- Distribution Name -->
                        <h3>{{ distribution.name }}</h3>
                      <!-- /Distribution Name -->

                      </div>
                      <!-- /Card Image & Distribution Name -->

                      <!-- Actions Dropdown -->
                      <div class="flex-inner">

                        <!-- Actions Dropdown -->
                        <actions-dropdown
                          v-if="canEdit && !statusOfCompleted"
                          ref="actionsDropdown"
                          :entity="distribution"
                          @edit="onEditDistribution"
                          @copy="onCopyDistribution"
                          @delete="onDeleteDistribution"
                        />
                      <!-- /Actions Dropdown -->

                      </div>
                    <!-- /Actions Dropdown -->

                    </div>
                    <!-- /Card Top -->

                    <!-- Card Bottom -->
                    <div class="card-bottom">
                      <p class="pre-formatted">
                        {{ distribution.description }}
                      </p>
                    </div>
                  <!-- /Card Bottom -->

                  </div>
                </el-col>
              </ib-draggable>
              <!-- /List Items -->
            </el-row>
            <el-row v-if="distributions.length > 5">
              <!-- Show All -->
              <el-col>
                <show-all-toggle
                  :show="showAllDistributionsList"
                  @click="showAllDistributionsList = !showAllDistributionsList"
                />
              </el-col>
              <!-- /Show All -->
            </el-row>
          </div>
          <!-- /List Wrapper -->

        </div>
        <!-- /Portfolio -->

      </div>
      <!-- /Content -->

      <!-- Add More Details -->
      <add-more-details
        :category="'concept'"
        :step="'distribution'"
        :concept-name="'distributionMoreDetails'"
        :completed-step-name="'distributionCompleted'"
      />
      <!-- /Add More Details -->

    </div>
    <!-- /Container -->

    <!-- Main Bottom Bar -->
    <main-bottom-bar
      :back="backNavigation"
      :next="nextNavigation"
      :completed-step-name="'distributionCompleted'"
      :step-filled="distributions.length > 0"
      @complete-step="onCompleteStep"
    />
    <!-- /Main Bottom Bar -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="distributionExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Dialog Add Distribution -->
    <dialog-add-distribution
      :dialog-add-distribution-visible="dialogAddDistributionVisible"
      :distribution-action="distributionAction"
      @close-dialog-add-distribution="onCloseDialogAddDistribution"
    />
    <!-- /Dialog Add Distribution -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

    <!-- Dialog Remove Examples -->
    <dialog-remove-examples
      :visible="dialogRemoveExamplesVisible"
      :loading="removeExamplesLoading"
      @no="dialogRemoveExamplesVisible = false"
      @yes="deleteExamples"
    />
    <!-- /Dialog Remove Examples -->

  </div>
  <!-- Distribution -->

</template>

<script>
import ActionsDropdown from '@/views/Home/StoryMode/Components/ActionsDropdown'
import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import AddNewCard from '@/components/_v2/AddNewCard'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import BusinessGuideHeader from '@/components/_v2/Common/BusinessGuideHeader'
import BusinessGuideSearch from '@/views/Home/Components/BusinessGuideSearch.vue'
import BusinessPlanAdditionalInfo from '@/views/Home/StoryMode/Components/BusinessPlanAdditionalInfo.vue'
import CompletedOverlay from '@/views/Home/StoryMode/Components/CompletedOverlay'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogAddDistribution from '@/views/Home/StoryMode/Concept/Distribution/Dialogs/DialogAddDistribution'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import DialogRemoveExamples from '@/views/Home/StoryMode/Components/DialogRemoveExamples'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import MainBottomBar from '@/views/Home/StoryMode/Components/MainBottomBar/MainBottomBar'
import MainNavigation from '@/views/Home/StoryMode/Components/MainNavigation/MainNavigation'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinEraseTemplate from '@/mixins/eraseTemplate'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import ShowAllToggle from '@/views/Home/StoryMode/Components/ShowAllToggle'
import TutorialTooltip from '@/views/Home/Components/TutorialTooltip'
import ViewSwitcher from '@/components/_v2/ViewSwitcher'

import { mapActions, mapGetters } from 'vuex'
import { getImageBySize } from '@/helpers/imageSizeHelper'

import InfoGuide from '@/views/Home/StoryMode/Components/Example/InfoGuide.vue'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'

export default {
  name: 'Distribution',

  components: {
    InfoGuide,
    AiBuddyDrawer,
    InfoGuideDrawer,
    BusinessPlanAdditionalInfo,
    BusinessGuideSearch,
    ActionsDropdown,
    AddMoreDetails,
    AddNewCard,
    BusinessGuideHeader,
    CompletedOverlay,
    DashboardHeader,
    DialogAddDistribution,
    DialogProcessCompleted,
    DialogRemoveExamples,
    EraseIconButton,
    MainBottomBar,
    MainNavigation,
    ShowAllToggle,
    TutorialTooltip,
    ViewSwitcher
  },

  mixins: [
    MixinProcessCompleted,
    MixinGuide,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles,
    MixinEraseTemplate
  ],

  data () {
    return {
      aiBuddyDrawerVisible: false,
      searchValue: '',
      removeExamplesLoading: false,
      dialogRemoveExamplesVisible: false,
      dialogAddDistributionVisible: false,
      distributionAction: null,
      guideVisible: false,
      showAllDistributionsGrid: false,
      showAllDistributionsList: false,
      dialogProcessCompletedVisible: false
    }
  },

  computed: {
    ...mapGetters('idea', [
      'getDistributions',
      'getStepIsCompleted',
      'getHasItemsFromTemplate'
    ]),

    distributions () {
      return this.getDistributions
    },

    statusOfCompleted () {
      return this.getStepIsCompleted('distributionCompleted')
    },

    isDistributionsEmpty () {
      return !this.distributions.length
    },

    distributionsToShowGrid () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterDistributions
      }

      return this.showAllDistributionsGrid === true
        ? this.distributions
        : this.distributions.slice(0, 4)
    },

    distributionsToShowList () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterDistributions
      }

      return this.showAllDistributionsList === true
        ? this.distributions
        : this.distributions.slice(0, 5)
    },

    filterDistributions () {
      return this.distributions.filter(item => item.name.toUpperCase().includes(this.searchValue.toUpperCase()))
    },

    hasNotAccess () {
      return !this.canEdit || this.statusOfCompleted
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getDistributions')
    },

    backNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.promotion'),
        route: 'business-guide-concept-promotion'
      }
    },

    nextNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.partners'),
        route: 'business-guide-concept-partners'
      }
    }
  },

  created () {
    this.openGuide('distributionGuide')
    this.getDistribution()
  },

  methods: {
    ...mapActions('idea', [
      'getDistribution',
      'deleteStoryModeDistribution',
      'deleteIdeaExamples',
      'updateOrderNumber'
    ]),

    onStartDistributionOrder () {
      this.$refs.actionsDropdown.forEach(dropdown => {
        dropdown.$refs.itemDropdown.visible = false
      })
    },

    onChangeDistributionOrder (data) {
      this.updateOrderNumber({
        type: 'distribution',
        data,
        oldOrderData: this.distributions,
        mutation: 'setDistribution'
      })
    },

    onEraseFromTemplate () {
      if (this.statusOfCompleted) {
        this.openedDialogProcessCompleted()

        return
      }
      this.dialogRemoveExamplesVisible = true
    },

    checkDistributionCard (distribution) {
      if (!this.canEdit) return
      if (this.statusOfCompleted) {
        this.dialogProcessCompletedVisible = true

        return
      }
      if (distribution.id) {
        this.onEditDistribution(distribution)
      }
    },

    onCloseDialogAddDistribution () {
      this.dialogAddDistributionVisible = false
      this.distributionAction = null
    },

    onActivateView (view) {
      this.$store.commit('itemsView/setDistributionsView', view)
    },

    onEditDistribution (distribution) {
      this.distributionAction = Object.assign({}, distribution)
      this.dialogAddDistributionVisible = true
    },

    onCopyDistribution (distribution) {
      this.distributionAction = Object.assign({}, distribution)
      this.distributionAction.id = 0
      this.dialogAddDistributionVisible = true
    },

    onDeleteDistribution (distribution) {
      this.deleteStoryModeDistribution(distribution.id)

      // Return to grid view, if there are no products
      if (this.$store.state.idea.storyMode.concept.distribution.distributions.length === 0) {
        this.onActivateView('grid')
      }
    },

    cardImage (img) {
      if (img) {
        return 'background-image: url(' + img + ')'
      } else {
        return ''
      }
    },

    cardImageBySize (image, size) {
      return image ? 'background-image: url(' + getImageBySize(image.sizes, size) + ')' : ''
    },

    onGuideExampleVisible () {
      this.guideVisible = !this.guideVisible
    },

    deleteExamples () {
      this.removeExamplesLoading = true
      this.deleteIdeaExamples('distributions')
        .then(response => {
          const { type, ids } = response
          this.deleteFromState({ type, ids })
          this.dialogRemoveExamplesVisible = false
          this.removeExamplesLoading = false
        }).catch(_ => { this.removeExamplesLoading = false })
    }
  }
}
</script>
