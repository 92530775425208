import { render, staticRenderFns } from "./DialogAddDistribution.vue?vue&type=template&id=449e7bac&scoped=true"
import script from "./DialogAddDistribution.vue?vue&type=script&lang=js"
export * from "./DialogAddDistribution.vue?vue&type=script&lang=js"
import style0 from "./DialogAddDistribution.vue?vue&type=style&index=0&id=449e7bac&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "449e7bac",
  null
  
)

export default component.exports